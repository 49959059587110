import { useLazyQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useDebounce, useOnClickOutside } from "usehooks-ts";
import { SEARCH_CUSTOMER } from "../../../../gqls/customer";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import Grow from "@mui/material/Grow";
import { customer } from "../../../../types/customer";
import CustomerSearchItem from "./customerSearchItem";
import { SEARCH_CONTACT } from "../../../../gqls/contact";
import { sort } from "fast-sort";

export default function CustomerSearch() {
  const [str, setStr] = useState("");

  const debouncedStr = useDebounce(str);
  const [results, setResults] = useState<customer[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const [search] = useLazyQuery(SEARCH_CUSTOMER, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      setResults(data.customers || []);
      inputRef.current?.blur();
    },
    onError(error) {
      console.log(error);
    },
  });

  const [searchContacts] = useLazyQuery(SEARCH_CONTACT);

  const searchFunc = async (Str: string) => {
    const yearNumber = Str.split(/[-~ ]/).map(n =>
      Number(n.replace(/[^0-9\.]+/g, ""))
    );

    let year = yearNumber[0] || 0;

    let number = yearNumber[1] || 0;

    let contactIds: number[] = [];

    if (year > 10000) {
      year = 0;
    }

    if (number > 100000) {
      number = 0;
    }

    const where: any = {
      _or: [
        { name: { _regex: Str } },
        { address: { _regex: Str } },
        { description: { _regex: Str } },
        { tasks: { report: { serials: { _contains: Str } } } },
        { _and: { year: { _eq: year }, number: { _eq: number } } },
      ],
    };

    const res = await searchContacts({
      variables: { Str },
      fetchPolicy: "network-only",
    });

    const results = res.data?.contacts?.map(c => c.id) || [];

    contactIds.push(...results);

    if (contactIds.length > 0) {
      where._or.push({
        _and: {
          contactIds: { _contained_in: contactIds, _neq: [] },
        },
      });
      where._or.push({
        contactIds: { _contains: contactIds },
      });
    }

    search({
      variables: { where },
    });

    return;
  };

  useEffect(() => {
    if (debouncedStr.trim() == "") {
      return setResults([]);
    }
    searchFunc(debouncedStr);
  }, [debouncedStr]);

  const searchResultBox = useRef<HTMLElement>(null);

  useOnClickOutside(searchResultBox, () => {
    setStr("");
    setResults([]);
  });

  return (
    <div className="w-full md:w-80 relative flex flex-row items-center gap-3">
      <div className="absolute left-2">
        <SearchIcon />
      </div>
      <input
        ref={inputRef}
        className="flex-1 ring-1 ring-gray-300 placeholder:text-gray-400 text-black bg-white rounded-sm px-2 py-[6px] pl-10 outline-none shadow-md md:shadow-none"
        style={{
          fontWeight: 500,
        }}
        placeholder="고객 검색"
        value={str}
        onChange={e => setStr(e.target.value)}
      />
      <Divider orientation="vertical" flexItem />
      <Grow in={results.length > 0} ref={searchResultBox}>
        <div className="absolute top-full mt-2 w-full pr-3 z-10">
          <div className="flex flex-col gap-2 p-2 rounded-sm bg-white shadow-md w-full ring-1 ring-gray-300">
            {sort(results)
              .desc("id")
              .map((res, i) => (
                <CustomerSearchItem
                  key={res.id}
                  customer={res}
                  setStr={setStr}
                  index={i}
                />
              ))}
          </div>
        </div>
      </Grow>
    </div>
  );
}
